import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import SEO from '../components/seo';
// import styles from './pageTemplate.module.scss';
import Layout from '../components/layout/layout';

const PageTemplate = ({ data }) => {
	const { markdownRemark } = data; // data.markdownRemark holds your post data
	const { frontmatter, html } = markdownRemark;

	return (
		<Layout>
			<SEO title={frontmatter.title} description="" />
			<article>
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-12">
							<div dangerouslySetInnerHTML={{ __html: html }} />
							{frontmatter.type === 'blog' && <Link to="/blog">Back to blog</Link>}
						</div>
					</div>
				</div>
			</article>
		</Layout>
	);
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
				type
      }
    }
  }
`;

PageTemplate.propTypes = {
	data: PropTypes.shape({}).isRequired
};

export default PageTemplate;
